import styled from 'styled-components';

export const MessageCreationPage = styled.div`
  .container {
    position: relative;
    margin-top: 15px;
    background: white;
    border-radius: 20px;
    padding: 46px 32px;
  }
`;

export const SubText = styled.div`
  color: #878787;
  font-size: 12px;
`;
