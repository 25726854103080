import { useState, useEffect, RefObject } from 'react';

interface useIsOverflowProps {
  ref: RefObject<HTMLDivElement> | null;
}
export const useIsOverflow = ({ ref }: useIsOverflowProps) => {
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (!ref || !ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      const { current } = ref;

      if (!current) return;

      const currentHeight = current.offsetHeight + 100;
      const currentWindowHeight = window.innerHeight;

      const hasOverflow = currentHeight > currentWindowHeight;

      setIsOverflow(hasOverflow);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, []);

  return isOverflow;
};
