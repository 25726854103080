import { BaseHTMLAttributes } from 'react';
import styled from 'styled-components';

type ColumnProps = {
  children?: React.ReactNode;
  $center?: boolean;
  $spaceBetween?: boolean;
  $spaceAround?: boolean;
  $alignCenter?: boolean;
  $grow?: boolean;
} & BaseHTMLAttributes<HTMLDivElement>;

const ColumnStyled = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  flex-grow: ${(props) => (props.$grow ? '1' : '0')};
  justify-content: ${(props) =>
    props.$spaceBetween
      ? 'space-between'
      : props.$center
      ? 'center'
      : props.$spaceAround
      ? 'space-around'
      : 'start'};
  ${(props) => (props.$alignCenter ? 'align-items: center' : '')};
`;

const Column = ({ children, ...props }: ColumnProps) => {
  return <ColumnStyled {...props}>{children}</ColumnStyled>;
};

export default Column;
