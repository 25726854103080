import { useContext, useRef, useState } from 'react';
import { TemplateContext } from '../../contexts/templates.context';
import MessageContainer, {
  NavigationHandle,
} from '../messages/MessageContainer';
import { KakaoInterfaceTop } from './kakao/kakao.style';
import { BiHomeAlt2 } from 'react-icons/bi';
import {
  IoChevronBackOutline,
  IoSearchOutline,
  IoCalendarOutline,
  IoChevronForwardOutline,
} from 'react-icons/io5';
import { RxHamburgerMenu } from 'react-icons/rx';
import {
  KakaoInterfaceTop2,
  DateContainer,
  IconContainer,
  Scroll,
  KakaoInterface,
} from './preview.styles';

type KakaoTemplateProps = {
  activeTab?: number;
};

const KakaoTemplate = ({ activeTab }: KakaoTemplateProps) => {
  const [logoIsHidden, setLogoIsHidden] = useState<boolean>(false);
  const ref = useRef<NavigationHandle>(null);
  const today = new Date();
  const days = [
    '일요일',
    '월요일',
    '화요일',
    '수요일',
    '목요일',
    '금요일',
    '토요일',
  ];

  const hideLogoHandle = (value: boolean) => {
    setLogoIsHidden(value);
  };

  const { brandData, currentTemplateData } = useContext(TemplateContext);

  return (
    <KakaoInterface
      $iscarousel={
        currentTemplateData?.messageType === 'FC' ||
        currentTemplateData?.messageType === 'FA'
          ? 'true'
          : 'false'
      }
    >
      <KakaoInterfaceTop>
        <div className="kk-interface-top-icons left">
          <IoChevronBackOutline />
        </div>
        <div className="kk-interface-top-icons right">
          <IoSearchOutline />
          <RxHamburgerMenu />
        </div>
      </KakaoInterfaceTop>
      <KakaoInterfaceTop2>
        <DateContainer>
          <IoCalendarOutline style={{ marginRight: '5px' }} />
          {today.getFullYear()}년 {today.getMonth() + 1}월 {today.getDate()}일{' '}
          {days[today.getDay()]} <IoChevronForwardOutline />
        </DateContainer>
        <IconContainer
          $iscarousel={
            currentTemplateData?.messageType === 'FC' ||
            currentTemplateData?.messageType === 'FA'
              ? 'true'
              : 'false'
          }
        >
          <BiHomeAlt2 />
        </IconContainer>
      </KakaoInterfaceTop2>

      <Scroll>
        <div className="brandData">
          {brandData?.kakaoChannelProfileImage && (
            <div className="brandLogo">
              <img
                src={brandData.kakaoChannelProfileImage}
                style={{
                  opacity:
                    (currentTemplateData?.messageType === 'FC' ||
                      currentTemplateData?.messageType === 'FA') &&
                    logoIsHidden
                      ? 0
                      : 1,
                  transition: 'opacity 200ms',
                }}
              />
            </div>
          )}

          {brandData?.brandName && (
            <div className="brandName">
              {currentTemplateData?.adFlag && '(광고) '}
              {brandData.brandName}
            </div>
          )}
        </div>

        {currentTemplateData && (
          <MessageContainer
            ref={ref}
            activeTab={activeTab}
            hideLogoHandle={hideLogoHandle}
          />
        )}
        {(currentTemplateData?.messageType === 'FC' ||
          currentTemplateData?.messageType === 'FA') && (
          <>
            <div
              className="prev-arrow"
              onClick={() => {
                if (ref.current) {
                  ref.current.handlePrev();
                }
              }}
            />
            <div
              className="next-arrow"
              onClick={() => {
                if (ref.current) {
                  ref.current.handleNext();
                }
              }}
            />
          </>
        )}
      </Scroll>
    </KakaoInterface>
  );
};

export default KakaoTemplate;
