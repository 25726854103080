import styled from 'styled-components';
import kakao from '../../assets/kakao.svg';
import play from '../../assets/icon-play.png';
import playOver from '../../assets/icon-play-over.png';
import theme from '../../styles/theme';

type IMessageWrap = {
  $isfriendtalk: boolean;
  $carousel: boolean;
};

type IKakaoMessage = {
  $carousel: boolean;
};

export const MessageWrap = styled.div<IMessageWrap>`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  position: relative;
  background: ${(props) => (props.$carousel ? 'transparent' : '#f9f9f9')};
  width: 90%;
  margin-top: 10px;
  font-size: 0.8rem;
  text-align: left;
  font-family: ${theme.fonts.primary_kr};
  line-height: 1.5;
  padding-bottom: 10px;

  &.sms-message-wrap {
    background: #fff;
  }

  &.isDashboard {
    margin: 20px auto;
    width: 260px;
    max-width: 90%;
    .swiper-slide {
      &:first-of-type {
        margin-left: 0px;
      }
    }
  }

  &.isKakaoMessage-noDashboard {
    margin: ${(props) =>
      props.$carousel ? '4px 10px 10px 0px' : '4px 10px 10px 30px'};
    position: relative;
    top: -14px;
    width: ${(props) =>
      props.$carousel
        ? '100%'
        : props.$isfriendtalk && !props.$carousel
        ? '87%'
        : '82%'};
  }
  .ad {
    text-transform: capitalize;
  }
`;
export const KakaoMessage = styled.div<IKakaoMessage>`
  padding: ${(props) => (props.$carousel ? '' : '10px 8px 0')};
  font-weight: 500;
  flex-grow: 1;
  position: relative;
  white-space: pre-line;
  overflow-wrap: break-word;
`;

interface ITemplateHeader {
  $type?: string;
}

export const TemplateHeader = styled.div<ITemplateHeader>`
  margin: 10px 8px 0;
  padding-bottom: 8px;
  font-size: ${(props) => (props.$type === 'FL' ? '13px' : '12px')};
  font-weight: 600;
  flex-grow: 1;
  position: relative;
  white-space: pre-line;
  word-break: break-all;
  border-bottom: ${(props) =>
    props.$type === 'FL' ? 'none' : `1px solid ${theme.colors.grey[100]}`};
  display: flex;
  justify-content: ${(props) =>
    props.$type === 'FL' ? 'center' : 'space-between'};
  text-transform: ${(props) => (props.$type === 'FL' ? 'uppercase' : 'none')};
  text-align: ${(props) => (props.$type === 'FL' ? 'center' : 'left')};
  .item-highlight-img {
    width: 25%;
    aspect-ratio: 1;
  }
  .title {
    border: none;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
  }
  .desc {
    color: ${theme.colors.grey[300]};
  }
`;

export const TemplateItem = styled.div`
  margin: 10px 8px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid ${theme.colors.grey[100]};
  ul {
    list-style: none;
    width: 100%;
    li {
      display: flex;
      justify-content: space-between;
      .label {
        color: ${theme.colors.grey[300]};
      }
      strong {
        font-size: 16px;
      }
      margin-bottom: 4px;
    }
  }
`;

export const Extra = styled.div`
  color: ${theme.colors.grey[300]};
  padding: 0 8px;
  word-break: break-all;
  margin-bottom: 13px;
`;

export const AlimtalkSender = styled.div`
  font-size: 0.8rem;
  color: #888888;
  margin-bottom: 8px;
`;

export const AlimtalkHeader = styled.div`
  content: 'AlimTalk';
  box-sizing: border-box;
  background-color: #fbe54d;
  width: 100%;
  padding: 8px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  &:after {
    background-image: url(${kakao});
    background-size: cover;
    content: '';
    color: white;
    border-radius: 50%;
    font-size: 5px;
    height: 20px;
    width: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -10px;
    top: -10px;
  }
  &.isDashboard {
    &:after {
      width: 24px;
      height: 24px;
    }
  }
`;

export const ImgStyled = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 15px 15px 0 0;

  &.ATImage {
    border-radius: 0;
  }

  &.smsImage {
    border-radius: 15px;
    margin-left: 10px;
    width: calc(100% - 10px);
    margin-bottom: 3px;
  }
`;

export const EmphasizeTypeText = styled.div`
  padding: 24px 0px;
  margin: 0px 15px;
  border-bottom: 1px solid ${theme.colors.grey[100]};

  .emphTitle {
    font-size: 0.95rem;
    font-weight: 700;
    overflow-wrap: break-word;
  }
  .emphSubTitle {
    color: #888888;
    font-size: 0.8rem;
    overflow-wrap: break-word;
  }
`;

export const SmsMessageWrap = styled.div`
  flex-grow: 1;
  white-space: pre-line;
  word-break: break-all;
  align-items: flex-start;
  background-color: #eee;
  position: relative;
  margin-left: 10px;
  border-radius: 20px;
  padding: 8px 15px;
  display: inline-block;
  margin-bottom: 3px;

  &.sms-title {
    font-weight: 600;
    width: fit-content;
  }

  &.last-message {
    min-height: 30px;
    &::before {
      content: '';
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: -7px;
      height: 20px;
      width: 20px;
      background: #eee;
      border-bottom-right-radius: 15px;
    }
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: -10px;
      width: 10px;
      height: 20px;
      background: #f9f9f9;
      border-bottom-right-radius: 10px;
    }
  }
`;

export const CarouselContainer = styled.div`
  /* .ad {
    padding: 0 25px;
  } */
  .swiper-wrapper {
    display: flex;
    align-items: stretch;
  }
  .swiper-slide {
    &:first-of-type {
      margin-left: 55px;
    }
    &:last-of-type {
      width: 0 !important;
    }
    background-color: #f9f9f9;
    border-radius: 15px;
    overflow: hidden;
    padding-bottom: 8px;
    height: auto;
    img {
      max-width: 100%;
    }
    .content {
      padding: 0 8px;
      .title {
        padding: 8px 0;
        text-transform: uppercase;
      }
      .desc {
        padding: 8px 0;
        white-space: pre-line;
        word-break: break-all;
      }
      &.carouselHead {
        .title {
          font-size: 16px;
          border-bottom: none;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 25px;
            height: 1px;
            border-bottom: 1px solid ${theme.colors.grey[100]};
          }
        }
        .desc {
          color: ${theme.colors.grey[300]};
        }
      }
    }
    .inlineButtons {
      margin-top: 0;
    }
    .inlineButtons div:nth-child(2) {
      margin-left: 0;
    }
    &.more {
      background: transparent;
      display: flex;
      align-items: center;
      width: 50px !important;
    }
  }
`;
export const More = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  span {
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const TemplateWideItem = styled.div`
  padding: 0 8px;
  ul {
    list-style: none;
    li {
      margin-bottom: 13px;
      display: flex;
      align-items: center;
      &:first-child {
        position: relative;
        img {
          width: 100%;
          height: 75px;
          object-fit: 100% auto;
        }
        .label {
          position: absolute;
          bottom: 4px;
          left: 8px;
          color: white;
          font-size: 16px;
          margin: 0;
          text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.51);
        }
      }
      .label {
        margin-left: 8px;
      }
      img {
        object-fit: cover;
        width: 40px;
        height: 40px;
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  &.inline {
    display: flex;
    gap: 4px;
    padding: 0 8px;
    > div {
      margin: 0;
      flex: 1 0 calc(50% - 12px);
      font-size: 12px;
    }
  }
`;

export const WideItemListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 15px;
  overflow: hidden;
  padding: 8px;
  width: 90%;
  margin-left: 24px;
  .header {
    font-size: 13px;
    font-weight: 600;
    white-space: pre-line;
    word-break: break-all;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 8px;
  }
  .first-item {
    position: relative;
    margin-bottom: 13px;
    img {
      height: 75px;
      width: 100%;
      object-fit: cover;
    }
    .title {
      position: absolute;
      bottom: 4px;
      left: 8px;
      color: white;
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.51);
    }
  }
  .item {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 13px;
    img {
      height: 40px;
      width: 40px;
    }
    .title {
      font-size: 0.8em;
    }
  }
  .button-row {
    display: flex;
    gap: 4px;
    & > div {
      flex-grow: 1;
      margin: 0;
      font-size: 12px;
    }
  }
`;
export const VideoContainer = styled.div`
  overflow: hidden;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  display: block;
  position: relative;
  iframe {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;
type PosterProps = {
  $image: string;
};
export const Poster = styled.div<PosterProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-image: ${(props) =>
    props.$image ? `url(${props.$image})` : 'none'};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 55px;
    background-image: url(${play});
    background-size: contain;
    background-repeat: no-repeat;
  }
  &:hover {
    &:after {
      background-image: url(${playOver});
    }
  }
`;
export const Notice = styled.div`
  font-size: 10px;
  text-align: center;
  padding: 0 20px;
`;
type ProductProps = {
  $messageType?: string;
};
export const Product = styled.div<ProductProps>`
  padding: 10px 0px;
  margin: 0px 5px;
  border-bottom: ${(props) =>
    props.$messageType === 'FM'
      ? `1px solid
    ${theme.colors.grey[100]}`
      : 'none'};
  .name {
    margin-bottom: 5px;
  }
`;
export const Price = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0 5px;

  .regular {
    font-size: 13px;
    font-weight: 600;
  }
  .discount {
    text-decoration: line-through;
    font-size: 10px;
  }
  .rate {
    font-size: 10px;
    color: ${theme.colors.red['normal']};
    &.fixed {
      display: inline-flex;
      &:after {
        content: '↓';
      }
    }
  }
`;
