import { styled } from 'styled-components';

export const TopButton = styled.div`
  cursor: pointer;
  border-radius: 50%;
  background-color: #666fb7;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  padding: 8px;
  width: 36px;
  height: 36px;

  bottom: 0;
  right: 0;
  position: sticky;
  margin-left: auto;
  top: calc(100% - 60px);
  transform: translateX(27px);

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.is-overflow {
    display: none;
  }
`;
