import styled from 'styled-components';

const KakaoInterfaceTop = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 6px;

  .kk-interface-top-icons {
    width: 40px;
    display: flex;

    &.left {
      gap: 4px;
      font-size: 12px;
    }

    &.right {
      font-size: 14px;
      gap: 8px;
      justify-content: flex-end;
    }
  }
`;

const DateRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .date-container {
    border-radius: 50px;
    background-color: #00000026;
    display: flex;
    align-items: center;
    color: white;
    font-size: 10px;
    padding: 3px 7px;
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: white;
    padding: 5px;
  }
`;

const ChannelImageCol = styled.div<{ imageUrl?: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 5px;
  .image {
    width: 24px;
    height: 24px;
    border-radius: 7px;
    background-size: contain;
    ${(props) => {
      return props.imageUrl !== undefined
        ? `background-image: url(${props.imageUrl});`
        : '';
    }}
  }
`;

const Wrapper = styled.div<{ imageUrl?: string }>`
  display: flex;
  flex-direction: column;
  align-items: start;
  max-height: 465px;
  flex-grow: 1;
  overflow-y: auto;
  .right-wrapper {
    width: 86%;
  }
  .channel-row {
    display: flex;
    align-items: center;
    .brand-name {
      font-size: 10px;
      color: #555b62;
      margin-right: 5px;
      margin-bottom: 5px;
      .ad {
        margin: 0 2px;
      }
    }
    .channel-image {
      width: 24px;
      height: 24px;
      border-radius: 7px;
      background-size: contain;
      ${(props) => {
        return props.imageUrl !== undefined
          ? `background-image: url(${props.imageUrl});`
          : '';
      }}
    }
  }
`;

export { KakaoInterfaceTop, DateRowStyled, ChannelImageCol, Wrapper };
