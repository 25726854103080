import styled from 'styled-components';
import theme from '../styles/theme';
const HighlightedVars = styled.span`
  color: ${theme.colors.red.normal};
  white-space: nowrap;
  font-weight: 600;
`;

const Container = styled.div`
  color: ${theme.colors.grey[400]};
`;

export const transformMessage = (message: string): JSX.Element | undefined => {
  if (message !== '') {
    const transformedText = message
      .replaceAll('\\n', '<br />')
      .split(/(#{\w+})/)
      .map((part, index) => {
        if (part.startsWith('#{') && part.endsWith('}')) {
          return <HighlightedVars key={index}>{part}</HighlightedVars>;
        }
        return part;
      });

    return <Container>{transformedText}</Container>;
  }
  return;
};
