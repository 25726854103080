import { TemplateButtons } from '../types/template.types';
import { Link } from 'react-router-dom';
import { Button } from './preview/preview.styles';

const ButtonPreview = ({
  text,
  buttonData,
  noMargin,
  type,
}: {
  buttonData: TemplateButtons;
  text: string;
  type: string;
  noMargin?: boolean;
}) => {
  const btnLink = buttonData.url_pc ? buttonData.url_pc : buttonData.url_mobile;
  return (
    <Button $type={type} className={noMargin ? 'no-margin' : ''}>
      {btnLink ? (
        <Link target="_blank" to={btnLink}>
          {text}
        </Link>
      ) : (
        text
      )}
    </Button>
  );
};

export default ButtonPreview;
