if (!process.env.REACT_APP_TAKO_API_BASE_URL) {
  throw new Error('API URL env variable missing');
}

if (!process.env.REACT_APP_TAKO_API_KEY) {
  throw new Error('API KEY env variable missing');
}

export const TAKO_API_BASE_URL = process.env.REACT_APP_TAKO_API_BASE_URL;
export const TAKO_API_KEY = process.env.REACT_APP_TAKO_API_KEY;
