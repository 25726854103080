import { Row } from '@tanstack/react-table';
import {
  TemplateButtons,
  BizMTemplateEntity,
} from '../../types/template.types';
import { TooltipArrow, TooltipContentRight } from './tooltip.styles';
import theme from '../../styles/theme';
import { styled } from 'styled-components';

type Template = {
  highlight?: boolean;
};

const TableWrapper = styled.table`
  margin-top: 15px;
  border-collapse: collapse;
  tr {
    color: ${theme.colors.grey[500]};
    text-align: center;
    white-space: nowrap;
  }
  .row {
    white-space: nowrap;
    text-align: center;
    border-bottom: 1px solid ${theme.colors.grey[200]};
  }
  .row td {
    padding: 15px 10px;
    color: ${theme.colors.grey[400]};
    font-size: 12px;
  }
  .row td div {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

type CombinedTemplateType = BizMTemplateEntity & Template;
const ButtonTooltipContent = ({ row }: { row: Row<CombinedTemplateType> }) => {
  const displayButtonsInfo = function () {
    const result = [];
    if (row.original.button1) {
      result.push(createRow(row.original.button1, 1));
    }
    if (row.original.button1) {
      result.push(createRow(row.original.button1, 2));
    }
    if (row.original.button3) {
      result.push(createRow(row.original.button3, 3));
    }
    if (row.original.button4) {
      result.push(createRow(row.original.button4, 4));
    }
    if (row.original.button5) {
      result.push(createRow(row.original.button5, 5));
    }
    return result;
  };

  const createRow = function (button: TemplateButtons, number: number) {
    return (
      <tr className="row">
        <td>{number}</td>
        <td>{button.type}</td>
        <td>{button.name}</td>
        <td>
          {button.url_mobile && <div>Mob: {button.url_mobile}</div>}
          {button.url_pc && <div>PC: {button.url_pc}</div>}
        </td>
      </tr>
    );
  };

  return (
    <TooltipContentRight>
      <TooltipArrow />
      <TableWrapper>
        <tr>
          <th>NO</th>
          <th>BUTTON TYPE</th>
          <th>BUTTON NAME</th>
          <th>LINK</th>
        </tr>
        {displayButtonsInfo().map((row) => row)}
      </TableWrapper>
    </TooltipContentRight>
  );
};

export default ButtonTooltipContent;
