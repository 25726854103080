import { styled } from 'styled-components';
import theme from '../../styles/theme';
import { ReactNode } from 'react';

export const TooltipContent = styled.div`
  visibility: hidden;
  position: absolute;
  z-index: 1;
  background: white;
  border: 2px solid ${theme.colors.primary[300]};
  color: ${theme.colors.primary[300]};
  border-radius: 5px;
  padding: 10px;
  white-space: pre-line;
  text-align: left;
  top: 0;
  font-size: 10px;
  min-width: 150px;
  transform: translateX(15%);
  &::after {
    content: ' ';
    position: absolute;
    top: 15%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent ${theme.colors.primary[300]} transparent
      transparent;
  }
`;

export const TooltipContentRight = styled.div`
  visibility: hidden;
  position: absolute;
  z-index: 1;
  background: white;
  border: 2px solid ${theme.colors.primary[200]};
  border-radius: 15px;
  border-top-right-radius: 0;
  padding: 20px;
  white-space: pre-line;
  text-align: left;
  font-size: 11px;
  transform: translateX(-100%);
  top: 50%;
`;

export const TooltipArrow = styled.div`
  content: '';
  position: absolute;
  right: -20px;
  top: -2px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 22px 19px 0 0;
  border-color: ${theme.colors.primary[200]} transparent transparent transparent;
  z-index: 0;
  &:after {
    content: '';
    position: absolute;
    right: -15px;
    top: -20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 21px 19px 0 0;
    border-color: white transparent transparent transparent;
    z-index: -1;
  }
`;

const TooltipWrap = styled.div`
  visibility: hidden;
  min-width: 210px;
  top: 45%;
  left: 100%;
  margin-left: 16px;
  transform: translate(0, -25%);
  padding: 10px;
  color: ${theme.colors.primary[100]};
  background-color: #ffffff;
  font-weight: normal;
  font-size: 13px;
  border-radius: 6px;
  position: absolute;
  z-index: 99999999;
  box-sizing: border-box;
  border: 1px solid #4750a3;

  i {
    position: absolute;
    top: 21%;
    right: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      left: 5px;
      top: 40%;
      background-color: #ffffff;
      border: 1px solid ${theme.colors.primary[100]};
      transform: rotate(-30deg) skewX(30deg) scaleY(cos(30deg));
    }
  }
`;

export const Tooltip = styled.div`
  position: relative;
  &:hover ${TooltipWrap} {
    visibility: visible;
  }
  &:hover ${TooltipContentRight} {
    visibility: visible;
  }
`;

export const ToolTipContainer = ({ children }: { children: ReactNode }) => {
  return (
    <TooltipWrap>
      {children}
      <i></i>
    </TooltipWrap>
  );
};
