import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1em;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  *{
    box-sizing: border-box;
    margin: 0;
    outline:0;
    padding: 0;
  }
  .inlineButtons{
    margin-top: 20px;
     display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 10px;
    &.nogap{
      gap: 0;
    }
    > *{
      flex: 1;
    justify-content: center;
    }
  }
`;

export default GlobalStyles;
