const colors = {
  primary: {
    100: '#1F255C',
    200: '#2C3583',
    300: '#4750A3',
    400: '#666FB7',
    500: '#8D94CF',
    600: '#CCCFE7',
    700: '#EFF0F5',
  },
  light_blue: '#81C9FE',
  purple: '#AC87FA',
  white: '#ffffff',
  yellow: '#fbe54d',
  orange: '#FFBA0A',
  red: {
    normal: '#F34D57',
    light: '#FFE8E9',
    hover: '#E7313C',
  },
  lightblue: '#81C9FE',
  green: '#59C86B',
  grey: {
    100: '#EEEEEE',
    200: '#D2D2D2',
    300: '#999999',
    400: '#555555',
    500: '#222222',
  },
  friendtalk: '#FB9EA3',
  alimtalk: '#4AC9FF',
  sms: '#B18FFB',
  mms: '#B18FFB',
  lms: '#B18FFB',
  total: '#666FB7',
  kakao_blue: '#AFC0CF',
};

const fonts = {
  primary: 'Poppins, sans-serif;',
  primary_kr: 'pretendard, sans-serif',
};

// for media query
const customMediaQuery = (maxWidth: number) =>
  `@media (max-width: ${maxWidth}px)`;

const breakpoints = {
  custom: customMediaQuery,
  xs: customMediaQuery(330),
  sm: customMediaQuery(592),
  md: customMediaQuery(768),
  lg: customMediaQuery(992),
  xl: customMediaQuery(1024),
  xxl: customMediaQuery(1280),
  xxxl: customMediaQuery(1440),
};

const getVwFunction = (width: number) => `calc(${width}vw / 14.4)`;

const getVhFunction = (width: number) => `calc(${width}vh / 9)`;

const theme = {
  colors,
  fonts,
  breakpoints,
  getVwFunction,
  getVhFunction,
  customMediaQuery,
};

export default theme;
