import { useContext, useEffect, useState } from 'react';
import {
  MessageTypeButton,
  MessageTypeSelectContainer,
} from './template_config.styles';
import {
  TemplateCategoryEnum,
  categoriesList,
} from '../../enums/templateForm.enums';
import { TemplateCategoryOption } from '../../types/jbForm.types';
import { TemplateContext } from '../../contexts/templates.context';

const MessageTypeSelect = ({
  onCategoryChange,
}: {
  onCategoryChange: (category: TemplateCategoryOption) => void;
}) => {
  const { initData, selectedTemplateCategory } = useContext(TemplateContext);
  const categoriesAvailable = categoriesList.filter((category) => {
    if (
      category.value !== TemplateCategoryEnum.SMS ||
      (initData &&
        initData.registeredSenderNumbers &&
        category.value === TemplateCategoryEnum.SMS)
    ) {
      return category;
    }
  });

  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (selectedTemplateCategory) {
      const categoryIndex = categoriesList.findIndex(
        (category) => category.label === selectedTemplateCategory.label,
      );
      setSelected(categoryIndex);
    }
  }, [selectedTemplateCategory]);

  const buttonClicked = function (index: number) {
    if (selected === index) {
      return;
    }
    setSelected(index);
    onCategoryChange(categoriesList[index]);
  };

  return (
    selectedTemplateCategory &&
    categoriesAvailable && (
      <MessageTypeSelectContainer>
        {categoriesAvailable.map((category, index) => {
          return (
            <MessageTypeButton
              key={category.value}
              $selected={selected === index}
              onClick={() => buttonClicked(index)}
              className={
                categoriesAvailable.length === 3 && index === 1
                  ? 'isMiddle'
                  : ''
              }
            >
              {categoriesList[index].label === 'SMS'
                ? 'SMS/LMS/MMS'
                : categoriesList[index].label}
            </MessageTypeButton>
          );
        })}
      </MessageTypeSelectContainer>
    )
  );
};
export default MessageTypeSelect;
