/* eslint-disable @typescript-eslint/no-explicit-any */
import Postmonger from 'postmonger';
import eventBus from './eventbus';

const connection = new Postmonger.Session();

connection.on('requestedSchema', function (data: any) {
  // console.log('================= requestedSchema ===============');

  eventBus.dispatch(
    'receivedSchema',
    data['schema'].map((x: any) => {
      return { value: x.key, label: x.name };
    }),
  );
});

export default connection;
