import { BaseHTMLAttributes } from 'react';
import { styled } from 'styled-components';

type ButtonProps = {
  $outlined?: boolean;
  $rounded?: boolean;
  color?: string;
  backgroundColor?: string;
  padding?: string;
  radius?: string;
  $bold?: boolean;
  size?: string;
} & BaseHTMLAttributes<HTMLDivElement>;

const ButtonStyled = styled.div<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '2px 4px')};
  cursor: pointer;
  ${(props) =>
    props.$outlined
      ? `border: 1px solid ${props.color ? props.color : 'black'};`
      : ''}
  border-radius: ${(props) =>
    props.$rounded ? '25px' : props.radius ? props.radius : '0'};
  ${(props) => (props.color ? `color: ${props.color};` : '')};
  ${(props) =>
    props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''};
  ${(props) => (props.$bold ? 'font-weight: bold;' : '')}
  ${(props) => (props.size ? `font-size: ${props.size};` : '')}
`;
const Button = ({ children, ...props }: ButtonProps) => {
  return <ButtonStyled {...props}>{children}</ButtonStyled>;
};

export default Button;
