import styled from 'styled-components';
import theme from '../../styles/theme';

const HighlightedVars = styled.span`
  background-color: ${theme.colors.red.normal}4D;
  color: ${theme.colors.grey[500]};
  /* white-space: nowrap; */
  font-weight: 600;
`;

const HighlightVars = (message: string) => {
  if (typeof message !== 'string' || message === '') return;
  const transformedText = message
    .replaceAll('\\n', '<br />')
    .split(/(#{\w+})/)
    .map((part, index) => {
      if (part.startsWith('#{') && part.endsWith('}')) {
        return <HighlightedVars key={index}>{part}</HighlightedVars>;
      }

      return part;
    });

  return <span>{transformedText}</span>;
};

export default HighlightVars;
