export const tempInitConfig = {
  name: '',
  id: 'a47c0c67-757e-42b1-ba78-88e8fad4964e',
  key: 'REST-1',
  type: 'REST',
  arguments: {
    execute: {
      inArguments: [
        {
          profileKey: '063665a78ef6dec8f1733711402b1c8adbf31d95',
          brandCode: 'asiance',
          reservedTime: '00000000000000',
          templateCode: 'asianceCancelRes01',
          receiverNum:
            '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.orderNumber}}',
          templateFields: {
            cancelReason:
              '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.cancelReason}}',
            customerFullName:
              '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.customerFullName}}',
            eventName:
              '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.eventName}}',
            reservationNo:
              '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.reservationNo}}',
          },
          setFallback: 'N',
          customKey1:
            '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.firstName}}',
          customKey2:
            '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.lastName}}',
          customKey3:
            '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.productName}}',
          messageSource: 'SFMC_JB',
          bulkSendId: '01JE5KS4W0HB7VEAQQBVP2XQ9J',
          allowFallback: false,
          overrideVariables: false,
        },
      ],
      url: '"https://dev-api.lets-tako.com/message"',
      verb: 'POST',
      format: 'json',
      body: '{"profileKey":"063665a78ef6dec8f1733711402b1c8adbf31d95","brandCode":"asiance","messageId":"{{Event.DEAudience-51f34a48-56e6-19d8-3866-0c86e39bc0b0.id}}","messageType":"AI","reservedTime":"00000000000000","templateCode":"asianceReservation01","receiverNum":"{{Event.DEAudience-51f34a48-56e6-19d8-3866-0c86e39bc0b0.phoneNumber}}","templateFields":{"customerFullName":"{{Event.DEAudience-51f34a48-56e6-19d8-3866-0c86e39bc0b0.customerFullName}}","docentYesNo":"{{Event.DEAudience-51f34a48-56e6-19d8-3866-0c86e39bc0b0.docentYesNo}}","eventName":"{{Event.DEAudience-51f34a48-56e6-19d8-3866-0c86e39bc0b0.eventName}}","reservationDate":"{{Event.DEAudience-51f34a48-56e6-19d8-3866-0c86e39bc0b0.reservationDate}}","reservationNo":"{{Event.DEAudience-51f34a48-56e6-19d8-3866-0c86e39bc0b0.reservationNo}}","totalVisitorNo":"{{Event.DEAudience-51f34a48-56e6-19d8-3866-0c86e39bc0b0.totalVisitorNo}}"},"smsOnly":"N"}',
      requestHeaders: {
        'x-api-key': 'XAT0gBjr6H4oHZqzNYjHC17DtM2f06lg7UuJZcfe',
      },
    },
  },
  configurationArguments: {
    save: {
      url: 'https://dev-api.lets-tako.com/hello',
      verb: 'POST',
      body: '',
      useJwt: false,
    },
    testSave: {
      url: 'https://dev-api.lets-tako.com/hello',
      verb: 'GET',
    },
  },
  metaData: {
    icon: 'https://tako-sfmc-jb-custom-activity-dev.s3.amazonaws.com/logo192.png',
    category: 'message',
    iconSmall: null,
    statsContactIcon: null,
    original_icon: 'logo192.png',
    isConfigured: true,
  },
  editable: true,
  outcomes: [
    {
      key: '5e8efbe2-4516-4aed-9836-680f42a4014c',
      next: 'WAITBYDURATION-1',
      arguments: {},
      metaData: {
        invalid: false,
      },
    },
  ],
  errors: [
    {
      errorDetail:
        'A custom activity or entry source failed validation. Check to ensure that the activity or entry source publishes to a valid endpoint.',
      errorCode: '121043',
      errorType: 'Error',
      additionalInfo: {
        exception:
          'InteractionStudio RestActivity Error Publishing Rest Activity Request to Endpoint on Save. Message | + {"message":"Missing Authentication Token"}',
        stacktrace:
          '   at ExactTarget.JourneyBuilder.Services.RestActivityService.PrepareAndCallRestEndpoint(ErrorManager errManager, Guid definitionId, Guid activityId, String apiEventName, JObject apiEvent, Guid applicationExtensionId, String partnerActivityId, Int32 interactionVersion, String interactionKey, Guid originalDefinitionId, Boolean executeAsync)',
        activityKey: 'REST-4',
        activityId: '7797c51f-5266-40d0-8a0b-2956562af6d0',
        activityType: 'REST',
        definitionId: '2753d2ea-7736-4880-a1cf-5686b2752d70',
      },
    },
  ],
};

export const tempActivityDefaultValues = {
  profileKey: '063665a78ef6dec8f1733711402b1c8adbf31d95',
  brandCode: 'asiance',
  reservedTime: '00000000000000',
  templateCode: 'asianceCancelRes01',
  receiverNum:
    '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.orderNumber}}',
  templateFields: {
    cancelReason:
      '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.cancelReason}}',
    customerFullName:
      '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.customerFullName}}',
    eventName:
      '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.eventName}}',
    reservationNo:
      '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.reservationNo}}',
  },
  setFallback: 'N',
  customKey1:
    '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.firstName}}',
  customKey2:
    '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.lastName}}',
  customKey3:
    '{{Event.DEAudience-938c8e45-7337-7bf9-1b51-187acb2cbd9f.productName}}',
  messageSource: 'SFMC_JB',
  bulkSendId: '01JE5KS4W0HB7VEAQQBVP2XQ9J',
  allowFallback: false,
  overrideVariables: false,
};
