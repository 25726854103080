/* eslint-disable @typescript-eslint/no-explicit-any */
type EventCallback<T> = (data: T) => void;

interface EventBus {
  listeners: Map<string, Set<EventCallback<any>>>;
  on<T>(event: string, callback: EventCallback<T>): void;
  dispatch<T>(event: string, data: T): void;
  remove(event: string): void;
}

const eventBus: EventBus = {
  listeners: new Map<string, Set<EventCallback<any>>>(),

  on<T>(event: string, callback: EventCallback<T>) {
    const eventListeners =
      this.listeners.get(event) || new Set<EventCallback<T>>();
    eventListeners.add(callback);
    this.listeners.set(event, eventListeners);

    document.addEventListener(event, (e: Event) => {
      if (e instanceof CustomEvent) {
        eventListeners.forEach((listener: EventCallback<T>) =>
          listener(e.detail),
        );
      }
    });
  },

  dispatch<T>(event: string, data: T) {
    document.dispatchEvent(new CustomEvent<T>(event, { detail: data }));
  },

  remove(event: string) {
    this.listeners.delete(event);
  },
};

export default eventBus;
