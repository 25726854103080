import { Cell, Row } from '@tanstack/react-table';
import { BizMTemplateEntity } from '../../types/template.types';
import { transformMessage } from '../../utils/transformMessageHighlightVars';
import ButtonPreview from '../ButtonPreview';
import { TooltipArrow, TooltipContentRight } from './tooltip.styles';

type Template = {
  highlight?: boolean;
};
type CombinedTemplateType = BizMTemplateEntity & Template;

const MessageTooltipContent = ({
  row,
  cell,
}: {
  row: Row<CombinedTemplateType>;
  cell: Cell<CombinedTemplateType, keyof CombinedTemplateType>;
}) => {
  return (
    <TooltipContentRight>
      <TooltipArrow />
      <div style={{ marginBottom: '10px' }}>
        {transformMessage(cell.getValue())}
      </div>
      {row.original.button1 && (
        <ButtonPreview
          type={row.original.button1.type}
          buttonData={{ name: '', type: 'WL' }}
          text={row.original.button1.name}
          noMargin={true}
        />
      )}
      {row.original.button2 && (
        <ButtonPreview
          type={row.original.button2.type}
          buttonData={{ name: '', type: 'WL' }}
          text={row.original.button2.name}
          noMargin={true}
        />
      )}
      {row.original.button3 && (
        <ButtonPreview
          type={row.original.button3.type}
          buttonData={{ name: '', type: 'WL' }}
          text={row.original.button3.name}
          noMargin={true}
        />
      )}
      {row.original.button4 && (
        <ButtonPreview
          type={row.original.button4.type}
          buttonData={{ name: '', type: 'WL' }}
          text={row.original.button4.name}
          noMargin={true}
        />
      )}
      {row.original.button5 && (
        <ButtonPreview
          type={row.original.button5.type}
          buttonData={{ name: '', type: 'WL' }}
          text={row.original.button5.name}
          noMargin={true}
        />
      )}
    </TooltipContentRight>
  );
};

export default MessageTooltipContent;
