import { IoChevronBackOutline, IoPersonCircle } from 'react-icons/io5';
import { useContext } from 'react';
import { TemplateContext } from '../../contexts/templates.context';
import MessageContainer from '../messages/MessageContainer';
import { SmsInterfaceTop, Scroll, SmsInterface } from './preview.styles';

const SmsTemplate = () => {
  const { currentTemplateData } = useContext(TemplateContext);
  return (
    <SmsInterface>
      <SmsInterfaceTop>
        <IoChevronBackOutline color="#3781df" size="22px" className="arrow" />
        <IoPersonCircle color="#8a8d95" size="50px" />
      </SmsInterfaceTop>

      <Scroll>{currentTemplateData && <MessageContainer />}</Scroll>
      {/* <PhoneTimeSignalRow isSms={true} />
      <Row
        $spaceBetween
        style={{ padding: '10px', borderBottom: '1px solid #d4d4d4' }}
      >
        <IoChevronBackOutline
          style={{ color: '#3781df', height: '22px', width: '22px' }}
        />
        <Column $alignCenter>
          <IoPersonCircle
            style={{ height: '50px', width: '50px', color: '#8a8d95' }}
          />
          {currentTemplateData?.senderNum && (
            <Row $center style={{ fontSize: '10px' }}>
              {currentTemplateData.senderNum}
            </Row>
          )}
          {currentTemplateData?.fallback && (
            <Row $center style={{ fontSize: '10px' }}>
              {currentTemplateData.fallback.senderNum}
            </Row>
          )}
        </Column>
        <div style={{ width: '22px' }} />
      </Row>
      {currentTemplateData && (
        <Row
          $alignStart
          style={{
            background: 'white',
            height: '434px',
            borderBottomLeftRadius: '28px',
            borderBottomRightRadius: '28px',
            padding: '10px',
            overflowY: 'auto',
            display: 'flex',
            flexGrow: 1,
          }}
        >
          <MessageContainer />
        </Row>
      )} */}
    </SmsInterface>
  );
};

export default SmsTemplate;
