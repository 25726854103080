import { styled } from 'styled-components';
import theme from '../../styles/theme';
import device from '../../assets/device-frame.png';
import ac from '../../assets/kakao-ac-icon.png';

export const SmsInterface = styled.div`
  height: 100%;
  padding: 60px 25px 20px;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;

  &.isDashboard {
    padding: 0;
  }
`;

export const SmsInterfaceTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .arrow {
    position: absolute;
    left: 0;
  }
`;

export const KakaoInterface = styled.div<{ $iscarousel: string }>`
  padding: 60px ${(props) => (props.$iscarousel === 'true' ? 0 : 25)}px 22px;
  height: 100%;

  background-color: ${theme.colors.kakao_blue};
  display: flex;
  flex-direction: column;

  &.isDashboard {
    padding: 0;
    position: relative;
    .prev-arrow {
      right: auto;
      left: 18px;
    }
    .next-arrow {
      left: auto;
      right: 33px;
    }
    .ad {
      margin-left: 0;
    }
  }

  .message {
    border-radius: 12px;
    overflow: hidden;
    background-color: ${theme.colors.white};
    font-family: ${theme.fonts.primary_kr};
    width: 226px;
  }

  .header {
    color: ${theme.colors.grey[500]};
    font-size: 11px;
    height: 30px;
    padding: 0 8px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .title {
    padding: 8px 8px 9px;
    font-size: 13px;
    font-weight: 700;
    border-bottom: 1px solid ${theme.colors.grey[100]};
  }
  .content {
    padding: 13px;
    * {
      font-size: 12px;
    }

    p {
      margin-bottom: 8px;
      &.footnote {
        color: ${theme.colors.grey[300]};
      }
    }
    .button {
      background-color: ${theme.colors.grey[100]};
      height: 30px;
      border-radius: 5px;
      margin-top: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ad {
    margin-left: 27px;
  }
  .prev-arrow,
  .next-arrow {
    position: absolute;
    z-index: 9999;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &:hover {
      &:after,
      &:before {
        border-color: ${theme.colors.primary[200]};
      }
    }
    &:after {
      display: block;
      content: '';
      width: 21px;
      height: 21px;
      border-top: 2px solid ${theme.colors.primary[500]};
    }
  }
  .prev-arrow {
    right: calc(100% + 5px);
    &:after {
      border-left: 2px solid ${theme.colors.primary[500]};
      transform: rotate(-45deg);
    }
  }
  .next-arrow {
    left: calc(100% + 5px);
    &:after {
      content: '';
      border-right: 2px solid ${theme.colors.primary[500]};
      transform: rotate(45deg);
    }
  }
  .brandData {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    margin-left: ${(props) => (props.$iscarousel === 'true' ? '25px' : '0')};
  }
  .brandLogo {
    width: 26px;
    height: 26px;
    overflow: hidden;
    border-radius: 11px;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .brandName {
    font-size: 10px;
    font-family: 'pretendard', sans-serif;
    color: ${theme.colors.grey[400]};
  }
`;

type ScrollProps = {
  $isKakaoMessage?: boolean;
};

export const Scroll = styled.div<ScrollProps>`
  flex: 1;
  overflow-y: scroll;
  scrollbar-color: #ffffff66 transparent;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 20px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.$isKakaoMessage ? '#ffffff66' : theme.colors.grey[300]};

    border-radius: 20px;
  }
`;

export const PhoneContainer = styled.div`
  text-align: center;
  > div {
    position: relative;
    width: 298px;
    height: 580px;
    margin: 0 auto;
    background-color: ${theme.colors.white};
    ${theme.breakpoints.xxl} {
      transform: scale(80%);
    }
    @media screen and (max-height: 700px) {
      transform: scale(80%);
    }
    @media screen and (max-height: 560px) {
      transform: scale(70%);
    }
    @media screen and (max-height: 500px) {
      transform: scale(60%);
    }
    &:after {
      content: '';
      background: url(${device}) no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 1px);
      height: calc(100% + 1px);
      /* z-index: 1; */
      pointer-events: none;
    }
    .screen {
      position: relative;
      z-index: 0;
      width: 100%;
      height: 100%;
    }
  }
  .scheduled {
    color: ${theme.colors.primary[200]};
    font-size: 13px;
    font-weight: 600;
    margin-top: 20px;
  }
`;

export const PhoneTimeSignalWrap = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  /* z-index: 10; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 40px 10px;
  font-weight: bold;
  font-family: ${theme.fonts.primary_kr}, sans-serif;
  * {
    font-size: 13px;
  }
  &.sms-phone-time {
    padding: 10px 19px 10px;
  }

  .verticalAlign {
    display: flex;
    gap: 2px;
  }
`;

export const KakaoInterfaceTop = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 6px;

  .kk-interface-top-icons {
    width: 40px;
    display: flex;

    &.left {
      gap: 4px;
      font-size: 12px;
    }

    &.right {
      font-size: 14px;
      gap: 8px;
      justify-content: flex-end;
    }
  }
`;

export const KakaoInterfaceTop2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 10px;
`;

export const KakaoMessageWrapRight = styled.div`
  width: 86%;
`;

export const DateContainer = styled.div`
  border-radius: 50px;
  background-color: #00000026;
  display: flex;
  align-items: center;
  color: white;
  font-size: 10px;
  padding: 3px 7px;
`;

export const IconContainer = styled.div<{ $iscarousel: string }>`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: white;
  padding: 5px;
  ${(props) => (props.$iscarousel === 'true' ? 'margin-right: 25px;' : '')}
`;

type ButtonProps = {
  $type: string;
};

export const Button = styled.div<ButtonProps>`
  background-color: ${(props) =>
    props.$type === 'AC' ? theme.colors.yellow : '#ededed'};
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 8px 8px 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  &:before {
    content: ${(props) => (props.$type === 'AC' ? `url(${ac})` : 'none')};
    display: inline-block;
    vertical-align: text-top;
    margin-right: 5px;
  }

  a {
    text-decoration: none;
    color: black;
  }

  &.no-margin {
    margin: 0;
  }
`;
