import styled from 'styled-components';

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000050;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .message-wrap {
    background-color: white;
    padding: 20px 25px;
  }
`;
