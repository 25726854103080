import Select, { GroupBase, StylesConfig, components } from 'react-select';
import { DropdownOption } from '../../types/jbForm.types';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import theme from '../../styles/theme';
import { FiSearch } from 'react-icons/fi';
import { FaChevronDown } from 'react-icons/fa';

interface IDropdownSelect {
  defaultValue?: DropdownOption;
  value?: DropdownOption | null;
  options: DropdownOption[] | null;
  placeholder?: string;
  onChange: (newValue: DropdownOption | null) => void;
  componentsProps?: Partial<
    SelectComponents<DropdownOption, false, GroupBase<DropdownOption>>
  >;
  stylesProps?: StylesConfig<DropdownOption, false, GroupBase<DropdownOption>>;
  isSearch?: boolean;
  width?: string;
  marginBottom?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ValueContainer = (props: any) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {!!props.children && (
            <FiSearch
              color={theme.colors.primary[200]}
              style={{ margin: '0 5px', fontSize: '16px' }}
            />
          )}
          {props.children}
        </div>
      </components.ValueContainer>
    )
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown
        color={theme.colors.primary[200]}
        style={{ marginRight: '10px' }}
      />
    </components.DropdownIndicator>
  );
};

const DropdownSelect = ({
  value,
  options,
  placeholder,
  onChange,
  defaultValue,
  isSearch,
  // stylesProps,
  componentsProps,
  width,
  marginBottom,
}: IDropdownSelect) => {
  let selectComponents = {
    IndicatorSeparator: () => null,
    DropdownIndicator,
    ...componentsProps,
  };

  if (isSearch) {
    selectComponents = { ...selectComponents, ValueContainer };
  }

  return (
    <Select
      defaultValue={defaultValue}
      value={value}
      options={options || []}
      placeholder={placeholder || ''}
      onChange={onChange}
      components={selectComponents}
      styles={{
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          paddingLeft: '16px',
        }),
        container: (baseStyles) => ({
          ...baseStyles,
          fontSize: '13px',
          width: width ? width : '100%',
          marginBottom: marginBottom ? marginBottom : '10px',
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          boxShadow: 'none',
          borderRadius: '5px',
          height: '43px',
          fontSize: '13px',
          borderColor: state.isFocused
            ? theme.colors.primary[200]
            : theme.colors.grey[500],
          ':hover': {
            borderColor: theme.colors.primary[200],
          },
        }),
        option: (baseStyles, { isDisabled, isFocused, isSelected }) => ({
          ...baseStyles,
          background:
            isFocused || isDisabled ? theme.colors.primary[700] : 'inherit',
          color: theme.colors.grey[500],
          fontWeight: isSelected ? 'bold' : 'normal',
          ':hover': {
            background: theme.colors.primary[700],
            color: theme.colors.grey[500],
          },
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          svg: {
            fill: theme.colors.grey[500],
          },
        }),
      }}
    />
  );
};

export default DropdownSelect;
