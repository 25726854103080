import { styled } from 'styled-components';
import theme from '../../styles/theme';

export const FormSectionWrap = styled.div`
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .label-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
    margin-top: 24px;
  }
  .label-text-wrap {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .label-text {
    font-weight: bold;
    &--primary {
      color: ${theme.colors.primary[300]};
    }
  }
  .icon {
    font-size: 14px;
  }
`;

export const SubText = styled.div`
  color: #878787;
  font-size: 12px;
  margin-right: 10px;
`;

export const WarningContainer = styled.div`
  background: #eff0f5;
  font-size: 12px;
  padding: 20px;
  margin-bottom: 16px;
  .field-label {
    font-size: 1rem;
    font-weight: bold;
    color: ${theme.colors.primary[200]};
    margin-bottom: 0.5rem;
  }

  p {
    &:first-of-type {
      margin-top: 0px;
    }
  }

  .red {
    color: #fa6d6d;
  }

  .bullet {
    margin-left: 10px;
  }

  ul {
    margin-top: 0px;
    padding-left: 20px;

    &:last-of-type {
      margin: 0;
    }
    li {
      margin-bottom: 4px;
      &:first-of-type {
        margin-top: 0.5em;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

export const ErrorMessage = styled.div`
  color: #fa6d6d;
  font-size: 11px;
`;
