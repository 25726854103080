import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SfmcJbFormData } from '../types/sfmcJbFormData.types';
import {
  CustomVariablesOption,
  DropdownOption,
  TemplateCategoryOption,
  TemplateOption,
} from '../types/jbForm.types';
import axios from 'axios';
import { TAKO_API_BASE_URL, TAKO_API_KEY } from '../config';
import { BizMTemplateEntity } from '../types/template.types';
import { TemplateCategoryEnum } from '../enums/templateForm.enums';
import { SavedActivityData } from '../types/savedActivityData';
import { BrandProperties } from '../types/brand.types';
import { CustomActivityContext } from './customActivity.context';

// import * as localData from '../localdata.json';

interface TemplateContextProviderProps {
  children: ReactNode;
}

type TemplateContextType = {
  initData: SfmcJbFormData;
  loading: boolean;
  brandName: string | null;
  brandData: BrandProperties | null;
  templateCategoriesList: TemplateCategoryOption[];
  selectedTemplateCategory: TemplateCategoryOption | null;
  setSelectedTemplateCategory: React.Dispatch<
    React.SetStateAction<TemplateCategoryOption | null>
  >;
  templatesList: TemplateOption[];
  setTemplatesList: React.Dispatch<React.SetStateAction<TemplateOption[]>>;
  selectedTemplate: TemplateOption | null;
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<TemplateOption | null>
  >;
  currentTemplateData: BizMTemplateEntity | null;
  setCurrentTemplateData: React.Dispatch<
    React.SetStateAction<BizMTemplateEntity | null>
  >;
  allTemplatesData: {
    [key: string]: BizMTemplateEntity;
  };
  selectedCategoryTemplatesData: { [key: string]: BizMTemplateEntity };
  changeTemplateListTemplates: (
    templates: { [key: string]: BizMTemplateEntity },
    category: DropdownOption,
  ) => void;
  templatesByCategories: { [key: string]: TemplateOption[] };
  smsTitle: string | null;
  setSmsTitle: React.Dispatch<React.SetStateAction<string | null>>;
  messageHasImage: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activityDefaultValues: any | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setActivityDefaultValues: React.Dispatch<React.SetStateAction<any | null>>;
  allowFallback: boolean;
  setAllowFallback: React.Dispatch<React.SetStateAction<boolean>>;
  overrideVariables: boolean;
  setOverrideVariables: React.Dispatch<React.SetStateAction<boolean>>;
  savedActivityData: SavedActivityData | null;
  setSavedActivityData: React.Dispatch<
    React.SetStateAction<SavedActivityData | null>
  >;
  customVariableOptions: CustomVariablesOption[];
  setCustomVariableOptions: React.Dispatch<
    React.SetStateAction<CustomVariablesOption[]>
  >;
  requireCustomField: boolean;
};

export const TemplateContext = createContext({} as TemplateContextType);

const TemplateContextProvider = ({
  children,
}: TemplateContextProviderProps) => {
  const [initData, setInitData] = useState<SfmcJbFormData>(
    {} as SfmcJbFormData,
  );
  const [allTemplatesData, setAllTemplatesData] = useState<{
    [key: string]: BizMTemplateEntity;
  }>({});

  const [selectedCategoryTemplatesData, setSelectedCategoryTemplatesData] =
    useState<{
      [key: string]: BizMTemplateEntity;
    }>({});

  const [templatesByCategories, setTemplatesByCategories] = useState<{
    [key: string]: TemplateOption[];
  }>({});
  const [templateCategoriesList, setTemplateCategoriesList] = useState<
    TemplateCategoryOption[]
  >([]);
  const [selectedTemplateCategory, setSelectedTemplateCategory] =
    useState<TemplateCategoryOption | null>(null);
  const [templatesList, setTemplatesList] = useState<TemplateOption[]>(
    [] as TemplateOption[],
  );
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateOption | null>(null);
  const [currentTemplateData, setCurrentTemplateData] =
    useState<BizMTemplateEntity | null>(null);
  const [brandName, setBrandName] = useState<string | null>(null);
  const [brandData, setBrandData] = useState<BrandProperties | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [smsTitle, setSmsTitle] = useState<string | null>(null);
  const [messageHasImage, setMessageHasImage] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [activityDefaultValues, setActivityDefaultValues] = useState<any>(null);
  const [allowFallback, setAllowFallback] = useState<boolean>(false);
  const [overrideVariables, setOverrideVariables] = useState<boolean>(false);
  const [savedActivityData, setSavedActivityData] =
    useState<SavedActivityData | null>(null);
  const [customVariableOptions, setCustomVariableOptions] = useState<
    CustomVariablesOption[]
  >([]);
  const [requireCustomField, setRequireCustomField] = useState(false);

  const { activityKey, journeyId } = useContext(CustomActivityContext);

  const changeTemplateListTemplates = function (
    templates: { [key: string]: BizMTemplateEntity },
    category: DropdownOption,
  ) {
    if (category.label === TemplateCategoryEnum.SMS) {
      setSelectedCategoryTemplatesData(allTemplatesData);
      return;
    }
    type TemplateData = {
      [key: string]: BizMTemplateEntity;
    };
    const newTemplateList = {} as TemplateData;
    Object.keys(templates).forEach((key) => {
      if (templates[key].templateCategory === category.label) {
        newTemplateList[key] = templates[key];
      }
    });
    setSelectedCategoryTemplatesData(newTemplateList);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInitDataResponse = function (data: any) {
    const templates = data.templates as {
      [key: string]: BizMTemplateEntity;
    };

    setAllTemplatesData(templates);
    // setSelectedCategoryTemplatesData(templates);

    // // to check for clients who don't have registered senderNumbers
    // delete data.kakaoDealer.senderNumber;

    setInitData(data as SfmcJbFormData);
    setBrandName(data.brandName);
    setBrandData({
      brandCode: data.brandCode,
      brandName: data.brandName,
      kakaoChannelId: data.kakoChannelId,
      kakaoChannelProfileImage: data.kakaoChannelProfileImage,
      profileKey: data.profileKey,
      msgProviderCode: data.msgProviderCode,
      registeredSenderNumbers: data.registeredSenderNumbers,
    });
    const options = [];
    const templateCategories: TemplateCategoryOption[] = [];
    const categories: string[] = [];

    for (const [key, value] of Object.entries(templates)) {
      const templateCode = key;
      const val = value;
      const category = val.templateCategory;
      if (val.templateCategory === 'ALIMTALK') {
        options.push({ value: templateCode, label: val.templateName });
      }
      const categoryOption = {
        value: category,
        label: TemplateCategoryEnum[category],
      };
      if (categories.indexOf(val['templateCategory']) === -1) {
        templateCategories.push(categoryOption);
        categories.push(val['templateCategory']);
      }
    }
    templateCategories.push({
      value: 'SMS',
      label: TemplateCategoryEnum.SMS,
    });
    templateCategories.sort((a, b) => {
      return a.value.localeCompare(b.value);
    });

    const activeTemplates = Object.values(templates).filter(
      (obj) => obj.templateStatus === 'ACTIVE',
    );

    activeTemplates.sort((a, b) =>
      a.templateName.localeCompare(b.templateName),
    );

    const templatesByCategoriesObj = Object.values(activeTemplates).reduce(
      (acc, curr) => {
        const category = curr.templateCategory;
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push({
          value: curr.templateCode,
          label: curr.templateName,
        });
        if (category !== TemplateCategoryEnum.SMS && curr.fallback) {
          if (!acc[TemplateCategoryEnum.SMS]) {
            acc[TemplateCategoryEnum.SMS] = [];
          }
          acc[TemplateCategoryEnum.SMS].push({
            value: curr.templateCode,
            label: curr.templateName,
          });
        }
        return acc;
      },
      {} as { [key: string]: TemplateOption[] },
    );

    setTemplatesByCategories(templatesByCategoriesObj);
    setSelectedTemplateCategory(templateCategories[0]);
    changeTemplateListTemplates(templates, templateCategories[0]);
    setTemplateCategoriesList(templateCategories);
    setTemplatesList(templatesByCategoriesObj[TemplateCategoryEnum.ALIMTALK]);
    setLoading(false);
  };

  useEffect(() => {
    // if (!activityKey || !journeyId) {
    //   return;
    // }
    axios({
      url: '/sfmc-jb-custom-activity/init-data',
      baseURL: TAKO_API_BASE_URL,
      method: 'get',
      headers: {
        'x-api-key': TAKO_API_KEY,
      },
    })
      .then((response) => {
        handleInitDataResponse(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [activityKey, journeyId]);

  useEffect(() => {
    if (selectedTemplate) {
      const { value } = selectedTemplate;
      setCurrentTemplateData(allTemplatesData[value]);
      if (allTemplatesData[value].templateImageUrl) {
        setMessageHasImage(true);
      } else {
        setMessageHasImage(false);
      }
      const { templateFields } = allTemplatesData[value];

      if (
        templateFields &&
        templateFields.length > 0 &&
        templateFields.some(
          (templateField) =>
            !customVariableOptions.find(
              (customVar) => customVar.label === templateField,
            ),
        )
      ) {
        setOverrideVariables(true);
        setRequireCustomField(true);
      } else {
        setRequireCustomField(false);
      }
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (!activityDefaultValues) return;

    const savedTemplateData =
      allTemplatesData[activityDefaultValues.templateCode];

    if (templateCategoriesList && savedTemplateData) {
      const { templateCategory } = savedTemplateData;
      let currentCategory = templateCategoriesList.find(
        (option) => option.value === templateCategory,
      );
      if (activityDefaultValues.smsOnly === 'Y' || templateCategory === 'SMS') {
        currentCategory = templateCategoriesList.find(
          (option) => option.value === 'SMS',
        );
      }
      if (currentCategory && templatesByCategories) {
        setSelectedTemplateCategory(currentCategory);

        changeTemplateListTemplates(allTemplatesData, currentCategory);

        setTemplatesList(templatesByCategories[currentCategory.label]);
      }
    }

    if (!currentTemplateData) {
      setCurrentTemplateData(savedTemplateData);
    }

    if (activityDefaultValues.allowFallback) {
      setAllowFallback(activityDefaultValues.allowFallback);
    }

    if (activityDefaultValues.smsTitle) {
      setSmsTitle(activityDefaultValues.smsTitle);
    }

    if (activityDefaultValues.overrideVariables) {
      setOverrideVariables(activityDefaultValues.overrideVariables);
    }
  }, [
    activityDefaultValues,
    allTemplatesData,
    savedActivityData,
    templateCategoriesList,
    templatesByCategories,
  ]);

  const value = {
    initData,
    loading,
    brandName,
    templateCategoriesList,
    selectedTemplateCategory,
    setSelectedTemplateCategory,
    templatesList,
    setTemplatesList,
    selectedTemplate,
    setSelectedTemplate,
    currentTemplateData,
    setCurrentTemplateData,
    allTemplatesData,
    templatesByCategories,
    smsTitle,
    setSmsTitle,
    messageHasImage,
    activityDefaultValues,
    setActivityDefaultValues,
    allowFallback,
    setAllowFallback,
    overrideVariables,
    setOverrideVariables,
    savedActivityData,
    setSavedActivityData,
    customVariableOptions,
    setCustomVariableOptions,
    selectedCategoryTemplatesData,
    changeTemplateListTemplates,
    brandData,
    requireCustomField,
  };

  return (
    <TemplateContext.Provider value={value}>
      {children}
    </TemplateContext.Provider>
  );
};

export default TemplateContextProvider;
