import theme from '../../styles/theme';
import ToggleTextField from '../ToggleTextField';

export enum SwitchFieldKeyEnum {
  phoneNumber = 'phoneNumber',
  messageId = 'messageId',
  customKey1 = 'customKey1',
  customKey2 = 'customKey2',
  customKey3 = 'customKey3',
}

export enum HandleSwitchEnum {
  mandatory = 'mandatory',
  override = 'override',
}

type SwitchFieldProps = {
  key: SwitchFieldKeyEnum;
  label: string;
  warning?: JSX.Element | string;
  error?: string;
};

export const requiredSwitchFieldsConfig: SwitchFieldProps[] = [
  {
    key: SwitchFieldKeyEnum.phoneNumber,
    label: 'Phone Number',
    warning: (
      <>
        <p>
          The{' '}
          <span
            style={{ color: theme.colors.primary[200], fontWeight: 'bold' }}
          >
            phoneNumber
          </span>{' '}
          field is required for sending messages and must contain the recipient
          {"'"}s mobile phone number.
        </p>
        <p>
          <span style={{ color: theme.colors.red.normal, fontWeight: 'bold' }}>
            *You must select a field that contains a valid mobile number to
            ensure messages are sent from the journey.
          </span>
        </p>
        <ToggleTextField
          title={
            <>
              See valid{' '}
              <span
                style={{ color: theme.colors.primary[200], fontWeight: 'bold' }}
              >
                phoneNumber
              </span>{' '}
              value requirements
            </>
          }
          content={
            <ul>
              <li>
                11 digit Korean mobile number starting with 010, no hyphens or
                spaces allowed between characters.
              </li>
              <li>
                For international numbers, must add &#39;+&#39; in front of
                country code.
              </li>
              <li>
                (O) Validphone number formats: ex) &#39;01012345678&#39;
                &#39;821012345678&#39; &#39;+821012345678&#39;
              </li>
              <li>
                (X) Invalid phone number formats: ex) ’+8201090724270’ or
                anything with space or hyphens in between characters
              </li>
            </ul>
          }
        />
      </>
    ),
    error: 'Please select a field for phoneNumber',
  },
  {
    key: SwitchFieldKeyEnum.messageId,
    label: 'Message ID',
    warning: (
      <>
        The{' '}
        <span style={{ color: theme.colors.primary[200], fontWeight: 'bold' }}>
          messageId
        </span>{' '}
        is a unique id set on all messages. This value must be unique. If you
        choose not to pass your own messageId, a unique messageId will be
        autogenerated for you.
        <p>
          <span style={{ color: theme.colors.red.normal, fontWeight: 'bold' }}>
            *If you choose to set your own messageId, please be sure that the
            messageId value fulfills the requirements set below.
          </span>
        </p>
        <ToggleTextField
          title={
            <>
              See valid{' '}
              <span
                style={{ color: theme.colors.primary[200], fontWeight: 'bold' }}
              >
                messageId
              </span>{' '}
              value requirements
            </>
          }
          content={
            <ul>
              <li>
                Max 20 characters. Exceeding this charcter limit will result in
                failure to send message.
              </li>
              <li>
                messageId value must be unique, otherwise message will fail to
                send due to duplicate messageId error.
              </li>
              <li>
                Recommended format of messageId is
                “&#123;BRANDCODE&#125;&#123;serial number&#125;” with no spaces
                in between.
              </li>
              <li>
                If you don&#39;t pass a messageId, a unique messageId will be
                generated for each message for you.
              </li>
            </ul>
          }
        />
      </>
    ),
    error:
      'messageId must be unique. Otherwise, message will fail to send due to duplicate messageId error.',
  },
];

export const customKeysSwitchFieldsConfig: {
  warning?: JSX.Element | string;
  switchFields: SwitchFieldProps[];
} = {
  warning: (
    // <>
    //   <p>
    //     The custom key fields{' '}
    //     <span style={{ color: theme.colors.primary[200], fontWeight: 'bold' }}>
    //       customKey1, customKey2, customKey3
    //     </span>{' '}
    //     are optional additional properties you can set on messages.
    //   </p>
    //   <ul>
    //     <li>You can set up to 3 custom keys per message.</li>
    //     <li>Custom Key values do not have to be unique. </li>
    //     <li>You can use these values to query the messages being sent.</li>
    //   </ul>
    //   {/* <p>
    //     <span style={{ color: theme.colors.red.normal, fontWeight: 'bold' }}>
    //       *If you choose to set your own messageId, please be sure that the
    //       messageId value fulfills the requirements set below.
    //     </span>
    //   </p> */}
    // </>
    <>
      <div className="field-label">
        Custom Key Fields (
        <span style={{ color: theme.colors.primary[200], fontWeight: 'bold' }}>
          customKey1, customKey2, customKey3
        </span>
        )
      </div>
      These optional properties allow you to set up to three custom keys per
      message. Custom key values do not need to be unique and can be used for
      querying messages later.
    </>
  ),
  switchFields: [
    {
      key: SwitchFieldKeyEnum.customKey1,
      label: 'Custom Key 1',
      error: 'Please select a field for customkey1',
    },
    {
      key: SwitchFieldKeyEnum.customKey2,
      label: 'Custom Key 2',
      error: 'Please select a field for customkey2',
    },
    {
      key: SwitchFieldKeyEnum.customKey3,
      label: 'Custom Key 3',
      error: 'Please select a field for customkey3',
    },
  ],
};
