import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoaderStyled = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3300ff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${rotate} 1s linear infinite;
`;

const Loader = () => {
  return <LoaderStyled />;
};

export default Loader;
