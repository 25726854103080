import { useState } from 'react';
import styled from 'styled-components';

type ToggleTextFieldProps = {
  title: JSX.Element | string;
  content: JSX.Element | string;
};

const ToggleWrap = styled.div`
  margin-top: 0.5em;
  cursor: pointer;

  .titleWrap {
    margin-top: 0.5em;
    cursor: pointer;
  }
`;

const ToggleTextField = ({ title, content }: ToggleTextFieldProps) => {
  const [openToggle, setOpenToggle] = useState(false);
  return (
    <ToggleWrap>
      <div className="titleWrap" onClick={() => setOpenToggle(!openToggle)}>
        {!openToggle ? '▶' : '▼'} {title}
      </div>
      {openToggle && <div>{content}</div>}
    </ToggleWrap>
  );
};

export default ToggleTextField;
