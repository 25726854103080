import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import JourneyBuilder from './routes/JourneyBuilder';
import TemplateContextProvider from './contexts/templates.context';
import CustomActivityContextProvider from './contexts/customActivity.context';
import GlobalStyles from './styles/GlobalStyles';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <CustomActivityContextProvider>
      <TemplateContextProvider>
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/" Component={JourneyBuilder} />
            <Route path="/index.html" Component={JourneyBuilder} />
          </Routes>
        </BrowserRouter>
      </TemplateContextProvider>
    </CustomActivityContextProvider>
  </React.StrictMode>,
);
