import { BaseHTMLAttributes } from 'react';
import styled from 'styled-components';

type RowProps = {
  children?: React.ReactNode;
  $center?: boolean;
  $spaceBetween?: boolean;
  $spaceAround?: boolean;
  $grow?: boolean;
  $margin?: string;
  $padding?: string;
  $alignStart?: boolean;
} & BaseHTMLAttributes<HTMLDivElement>;

const RowStyled = styled.div<RowProps>`
  display: flex;
  flex-grow: ${(props) => (props.$grow ? '1' : '0')};
  align-items: ${(props) => (props.$alignStart ? 'flex-start' : 'center')};
  padding: ${(props) => (props.$padding ? props.$padding : '0')};
  justify-content: ${(props) =>
    props.$spaceBetween
      ? 'space-between'
      : props.$center
      ? 'center'
      : props.$spaceAround
      ? 'space-around'
      : 'flex-start'};
  ${(props) => props.$margin && `margin: ${props.$margin}`};
`;

const Row = ({ children, ...props }: RowProps) => {
  return <RowStyled {...props}>{children}</RowStyled>;
};

export default Row;
